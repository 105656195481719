import {
  Carport,
  MeasureData,
  RoofType,
} from '../components/configurator/models';
import {
  GO_TO_STEP,
  LOAD_STATE_FROM_URL,
  NEXT_STEP,
  PREVIOUS_STEP,
  UPDATE_FORM,
} from './actions';

export interface AppState {
  currentStep: number;
  visitedSteps: number[];
  length: MeasureData;
  width: MeasureData;
  height: MeasureData;
  amountOfCollaboration: MeasureData;
  data: Carport;
}

export const initialState: AppState = {
  currentStep: 0,
  visitedSteps: [],
  length: new MeasureData(300, 800),
  width: new MeasureData(200, 700),
  height: new MeasureData(200, 350),
  amountOfCollaboration: new MeasureData(0, 25),
  data: new Carport(
    450,
    300,
    250,
    RoofType.TRAPEZOIDAL_ROOF,
    'no',
    false,
    false,
    false,
    0,
  ),
};

interface Action {
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

const hasValidLength = (state: AppState, length: number) =>
  length && length >= state.length.min && length <= state.length.max;

const hasValidWidth = (state: AppState, width: number) =>
  width && width >= state.width.min && width <= state.width.max;

const hasValidHeight = (state: AppState, height: number) =>
  height && height >= state.height.min && height <= state.height.max;

const hasValidRoofType = (roofType: RoofType) =>
  roofType &&
  (roofType === RoofType.TRAPEZOIDAL_ROOF || roofType === RoofType.OTHER);

const hasValidWalls = (
  leftWall: 'true' | 'false',
  backWall: 'true' | 'false',
  rightWall: 'true' | 'false',
) =>
  (leftWall === 'true' || leftWall === 'false') &&
  (backWall === 'true' || backWall === 'false') &&
  (rightWall === 'true' || rightWall === 'false');

const hasValidAmountOfCollaboration = (amountOfCollaboration: number) =>
  amountOfCollaboration &&
  amountOfCollaboration >= 0 &&
  amountOfCollaboration <= 25 &&
  amountOfCollaboration % 5 === 0;

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer = (state: AppState = initialState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case PREVIOUS_STEP:
      return {
        ...state,
        visitedSteps:
          state.visitedSteps.indexOf(state.currentStep - 1) === -1
            ? [...state.visitedSteps, state.currentStep - 1]
            : state.visitedSteps,
        currentStep: state.currentStep - 1,
      };
    case NEXT_STEP:
      return {
        ...state,
        visitedSteps:
          state.visitedSteps.indexOf(state.currentStep + 1) === -1
            ? [...state.visitedSteps, state.currentStep + 1]
            : state.visitedSteps,
        currentStep: state.currentStep + 1,
      };
    case GO_TO_STEP:
      return {
        ...state,
        visitedSteps:
          state.visitedSteps.indexOf(payload as number) === -1
            ? [...state.visitedSteps, payload]
            : state.visitedSteps,
        currentStep: payload,
      };
    case UPDATE_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
      };

    case LOAD_STATE_FROM_URL: {
      const isValidSharedLink =
        hasValidHeight(state, payload.height) &&
        hasValidLength(state, payload.length) &&
        hasValidWidth(state, payload.width) &&
        hasValidRoofType(payload.roofType) &&
        hasValidWalls(payload.leftWall, payload.backWall, payload.rightWall) &&
        hasValidAmountOfCollaboration(payload.amountOfCollaboration);

      return isValidSharedLink
        ? {
            ...state,
            currentStep: 5,
            visitedSteps: [1, 2, 3, 4],
            data: {
              ...state.data,
              length: parseInt(payload.length, 10),
              width: parseInt(payload.width, 10),
              height: parseInt(payload.height, 10),
              roofType: payload.roofType,
              walls:
                payload.leftWall === 'true' ||
                payload.backWall === 'true' ||
                payload.rightWall === 'true'
                  ? 'yes'
                  : 'no',
              leftWall: payload.leftWall === 'true' && true,
              backWall: payload.backWall === 'true' && true,
              rightWall: payload.rightWall === 'true' && true,
              amountOfCollaboration: +payload.amountOfCollaboration,
            },
          }
        : state;
    }
    default:
      return state;
  }
};

export default reducer;
